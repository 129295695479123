import {Component, OnInit, OnDestroy} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {

    loading: boolean = true;
    datalist: any;
    selectId: any = '';
    title: any = '';
    subscript: any;
    imgList = {
        U6: 'assets/images/banner2.jpg',
        U8: 'assets/images/banner1.jpg',
        U10: 'assets/images/banner3.jpg',
        U12: 'assets/images/banner4.jpg',
        U15: 'assets/images/banner3.jpg',
    };

    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) {
        //获取参数值
        this.selectId = this.activatedRoute.snapshot.params['id'];
        //订阅路由参数，路由传递id改变之后执行
        this.subscript = this.activatedRoute.params.subscribe((params: Params) => {
            this.selectId = params["id"];
            this.getData();
        });
    }

    ngOnInit() {
        // this.getData();
    }

    getData() {
        this.loading = true;
        if (this.selectId) {
            const config = {
                "table": "selection_home",
                "where": {
                    "selection_home_id": this.selectId
                },
                "page": "1",
                "perPage": "10000"
            };
            this.dataService.getCommonData(config).then(resp => {
                if (resp.code === 200) {
                    this.datalist = resp.data[0]['selection_home_content'];
                    this.title = resp.data[0]['selection_home_name'];
                    this.loading = false;
                }
            })
        } else {
            const config = {
                "table": "selects",
                "where": {},
                "return": "row_array",
                "page": "1",
                "perPage": "10000"
            };
            this.dataService.getCommonData(config).then(resp => {
                if (resp.code === 200) {
                    this.datalist = resp.data['selects_content'];
                    this.loading = false;
                }
            })
        }

    }

    ngOnDestroy() {
        this.subscript.unsubscribe();
    }
}
