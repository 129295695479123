import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {TabsetConfig} from 'ngx-bootstrap';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HttpProvider} from "../../../@core/utils/http.provider";
import {ToastrService} from 'ngx-toastr';
import {GlobalsService} from "../../../@core/data/globals.service";
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from "ngx-uploader";

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';


@Component({
    selector: 'app-personal',
    templateUrl: './personal.component.html',
    styleUrls: ['./personal.component.scss'],
    providers: [TabsetConfig]
})
export class PersonalComponent implements OnInit {
    tab: any = 'personal';
    notification = [];
    event = [];
    league = [];
    camps = [];
    tournament = [];
    events = [];
    user: any = '';
    personalForm: FormGroup;
    player_logintype = '';
    parent: any;
    loading: boolean = false;

    imgBatchUploading: boolean = false;
    imgUploading: boolean = false;
    onUploadOutputType: any;
    files = [];
    uploadfile_src = '';
    uploadfile_name = '';
    position = [];
    shoot = [];
    jerseysize = [];
    studyingGrade = [];
    nationality = [];
    selectId: any = '';
    modalRef: BsModalRef;
    detailInfo = [];
    CampsdetailInfo = [];
    TournamentdetailInfo = [];
    EventsdetailInfo = [];
    playerInfo = [];
    showDetail: boolean = false;
    showCampsDetail: boolean = false;
    showTournamentDetail: boolean = false;
    showEventsDetail: boolean = false;
    showDetailAll:boolean = false;
    elite_edgework_training = [];
    city_league_division = [];
    city_league_division_2nd = [];
    position_played = [];
    studying_grade = [];

    nationalityValue: any = '';
    positionValue: any = '';
    shootValue: any = '';
    jerseysizeValue: any = '';
    studyingGradeValue: any = '';


    divisionAndTeamData = [];
    annualArray = [];
    feeData = [];
    feeOrderitem = [];

    @ViewChild('template_detail', {static: true}) template_detail: ElementRef;

    passwordForm = new FormGroup({
        player_old_password: new FormControl('', [Validators.required]),
        player_password: new FormControl('', [Validators.required]),
        player_comfirmpassword: new FormControl('', [Validators.required]),
    });

    constructor(private router: Router,
                public dataService: DataService,
                public httpProvider: HttpProvider,
                // public UploadFile:UploadFile,
                private httpClient: HttpClient,
                public globals: GlobalsService,
                private toastrService: ToastrService,
                private activatedRoute: ActivatedRoute,
                private modalService: BsModalService,) {
        //获取参数值
        if (this.activatedRoute.snapshot.params['id']) {
            this.tab = this.activatedRoute.snapshot.params['id'];
        }

    }

    ngOnInit() {
        this.personalForm = new FormGroup({
            player_first_name: new FormControl('', [Validators.required]),
            player_last_name: new FormControl('', [Validators.required]),
            player_email: new FormControl('', [Validators.required, Validators.pattern(EMAILPATTERN)]),
            player_gender: new FormControl('', [Validators.required]),
            player_birth: new FormControl('', [Validators.required]),
            player_height: new FormControl('', [Validators.required]),
            player_weight: new FormControl('', [Validators.required]),
            player_description: new FormControl(''),
            player_position_id: new FormControl('', [Validators.required]),
            player_shoot_id: new FormControl('', [Validators.required]),
            player_jerseysize_id: new FormControl('', [Validators.required]),
            player_attending_school: new FormControl('', [Validators.required]),
            player_studying_grade_id: new FormControl('', [Validators.required]),
            player_nationality_id: new FormControl('', [Validators.required]),
            mother_name: new FormControl('', [Validators.required]),
            mother_contact: new FormControl('', [Validators.required]),
            mother_email: new FormControl('', [Validators.required]),
            father_email: new FormControl('', [Validators.required]),
            father_name: new FormControl('', [Validators.required]),
            father_contact: new FormControl('', [Validators.required]),
            player_guardian_name: new FormControl('', [Validators.required]),
        });

        const token = localStorage.getItem("token");
        if (token) {
            
            this.getNotification();
            this.getPlayerOrder('event');
            this.getPlayerOrder('league');
            this.getPlayerCamps();
            this.getPlayerTournament();
            //20210527
            this.getPlayerEvents();

            this.getposition();
            // this.getshoot();
            // this.getjerseysize();
            this.getstudyingGrade();
            this.getnationality();
            this.getProfile(true);
            this.loading = true;
        } else {
            this.router.navigate(['/login'])
        }
        // this.getPlayerDataSelect('position_played');
        this.getPlayerDataSelect('shoot');
        this.getPlayerDataSelect('jerseysize');
        this.getPlayerDataSelect('studying_grade');
        // this.getPlayerDataSelect('nationality');


    }

    openModal(template_detail) {
        this.modalRef = this.modalService.show(template_detail);
    }

    loginout() {
        this.dataService.logout().then(resp => {
            if (resp && resp.code === 200) {
                localStorage.setItem('token', null);
                // localStorage.setItem('user', null);
                localStorage.removeItem('user');
                localStorage.removeItem('payInfo');
                localStorage.removeItem('league');
                localStorage.removeItem('choiceId');
                localStorage.removeItem('otherData');

                this.httpProvider.publishSessionId('');
                this.router.navigate(['/home']);
                this.globals.globalVar.next([{getInfo: true}]);
            }
        })
    }

    getPlayerDataSelect(tableName) {

        const config = {
            "table": tableName,
            "where": {},
            "page": "1",
            "perPage": "10000"
        };

        this.dataService.getCommonData(config).then(resp => {

            let returnData = [];
            // console.log(resp);
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    returnData.push({'name': val[tableName + '_name'], 'id': val[tableName + '_id']});
                })
                // console.log(returnData);
                this[tableName] = returnData;

            }
        })
    }

    detail(item) {
        if (item !== '') {
            this.playerInfo = JSON.parse(localStorage.getItem("user"));
            this.detailInfo = item;
            this.showDetail = true;
            this.showDetailAll = true;
            // console.log(this.showDetailAll);            
            this.getNationalityValue(this.playerInfo['player_nationality_id']);
            this.getStudyingGradeValue(this.playerInfo['player_studying_grade_id']);
            this.getJerseysizeValue(this.playerInfo['player_jerseysize_id']);
            this.getShootValue(this.playerInfo['player_shoot_id']);
            this.getPositionPlayedValue(this.playerInfo['player_position_id']);
            return;

        }
    }


    campsdetail(item) {
        if (item !== '') {
            this.playerInfo = JSON.parse(localStorage.getItem("user"));
            this.CampsdetailInfo = item;
            this.showCampsDetail = true;
            this.showDetailAll = true;
            
            return;

        }
    }

    tournamentdetail(item) {
        if (item !== '') {
            this.playerInfo = JSON.parse(localStorage.getItem("user"));
            this.TournamentdetailInfo = item;
            this.showTournamentDetail = true;
            this.showDetailAll = true;
            
            return;

        }
    }


    eventsdetail(item) {
        if (item !== '') {
            this.playerInfo = JSON.parse(localStorage.getItem("user"));
            this.EventsdetailInfo = item;
            if (item.divisionAndTeam.length > 0) {
                let divisionAndTeam = item.divisionAndTeam;
                this.divisionAndTeamData = divisionAndTeam;
                let divisionArray= [];
                let teamArray= [];
                this.annualArray = [];
                this.feeOrderitem=[];
               
                //需要在详情页显示的
                divisionAndTeam.forEach((dtData)=>{
                    if(dtData.z_annual_banquet_option_type =='division'){
                        //this.annual_banquet_division_id = dtData.z_annual_banquet_option_id;
                        // divisionArray.push((dtData.z_annual_banquet_option_name) + '...$' +(dtData.z_annual_banquet_option_price));
                        divisionArray.push(dtData.z_annual_banquet_option_name);
            
                    }
                    if(dtData.z_annual_banquet_option_type =='team'){
                        //this.annual_banquet_team_id = dtData.z_annual_banquet_option_id;
                        // teamArray.push((dtData.z_annual_banquet_option_name) + '...$' +(dtData.z_annual_banquet_option_price));
                        teamArray.push(dtData.z_annual_banquet_option_name);
                    }
                })

                this.annualArray.push({"division":divisionArray});
                this.annualArray.push({"team":teamArray});
                //console.log('annualArray',this.annualArray);
            }
           
            //fee
            if(item.feeData.length > 0){
            
                let feeData = item.feeData;
                this.feeData = feeData;
                
                feeData.forEach((dtData)=>{
                    this.feeOrderitem.push((dtData.z_annual_banquet_fee_option_name) + '...$' +(dtData.z_annual_banquet_fee_option_price)+"/"+dtData.z_annual_banquet_fee_option_number+" person");

                    
                })
                //console.log('feeOrderitem',this.feeOrderitem);
            }
            


            this.showEventsDetail = true;
            this.showDetailAll = true;
            
            return;

        }
    }

    getNationalityValue(event) {

        if (event != null) {

            this.nationalityValue = this.arrayLookup(this.nationality, 'id', event);


        }

    }

    getStudyingGradeValue(event) {

        if (event != null) {

            this.studyingGradeValue = this.arrayLookup(this.studying_grade, 'id', event);

        }
    }

    getJerseysizeValue(event) {

        if (event != null) {
            this.jerseysizeValue = this.arrayLookup(this.jerseysize, 'id', event);

        }

    }

    getShootValue(event) {
        if (event != null) {
            //console.log(this.shoot);
            this.shootValue = this.arrayLookup(this.shoot, 'id', event);


        }

    }

    getPositionPlayedValue(event) {
        if (event != null) {
            this.positionValue = this.arrayLookup(this.position_played, 'id', event);

        }
    }

    arrayLookup(data, key, value) {
        var targetValue = "";

        for (var i = 0; i < data.length; i++) {

            if (parseInt(data[i][key]) == parseInt(value)) {
                return data[i];
            }
        }

    }

    /**
     * thisIsFunction
     * 获取消息通知
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-03-01
     */
    getNotification() {
        let data = {
            notification_type: 'email'
        };
        this.dataService.getBaseData('player/notification', data).then(resp => {
            if (resp) {
                this.notification = resp.data.list
            }
        })
    }

    /**
     * thisIsFunction
     * 获取联赛/活动订单
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-03-01
     */
    getPlayerOrder(type) {
        let data = {
            player_order_type: type
        };
        this.dataService.getBaseData('player/order', data).then(resp => {
            if (resp) {
                this[type] = resp.data;
            }
        })
    }

    getPlayerCamps(){


        this.dataService.getBaseData('player/campsOrder').then(resp => {
            console.log('getPlayerCamps resp',resp)
            if (resp) {
                this.camps = resp.data;
            }
        })


    }

    getPlayerTournament(){


        this.dataService.getBaseData('player/tournamentOrder').then(resp => {
            console.log('getPlayerTournament resp',resp)
            if (resp) {
                this.tournament = resp.data;
            }
        })


    }

    getPlayerEvents(){


        this.dataService.getBaseData('player/eventsOrder').then(resp => {
            console.log('getPlayerEvents resp',resp)
            if (resp) {
                this.events = resp.data;
                // division 和 team 
            }
        })


    }

    /**
     * thisIsFunction
     * 修改个人信息
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-03-01
     */
    updateProfile() {
        const data = this.personalForm.value;
        data['player_logintype'] = this.user.player_logintype;
        this.dataService.postBaseData('player/updateProfile', data).then(resp => {
            if (resp.code == 200) {
                if (this.user.player_logintype = 'email' && data.player_email !== this.user.player_email) {
                    this.loginout();
                } else {
                    this.toastrService.success('Success', 'Modified success');
                    this.getProfile();
                }
            }
        })
    }


    /**
     * thisIsFunction
     * 获取个人资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getProfile(type?) {
        this.dataService.getBaseData('player/getProfile').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    this.user = resp.data;
                    localStorage.setItem("user", JSON.stringify(resp.data));
                    if (type) {
                        this.loading = false;
                        this.player_logintype = this.user.player_logintype;
                        this.globals.globalVar.next([{img: this.user.player_photo}]);
                        this.personalForm.patchValue({
                            player_first_name: this.user['player_first_name'],
                            player_last_name: this.user['player_last_name'],
                            player_email: this.user['player_email'],
                            player_gender: this.user['player_gender'],
                            player_birth: this.user['player_birth'],
                            player_height: this.user['player_height'],
                            player_weight: this.user['player_weight'],
                            player_description: this.user['player_description'],
                            player_position_id: this.user['player_position_id'],
                            player_shoot_id: this.user['player_shoot_id'],
                            player_jerseysize_id: this.user['player_jerseysize_id'],
                            player_attending_school: this.user['player_attending_school'],
                            player_studying_grade_id: this.user['player_studying_grade_id'],
                            player_nationality_id: this.user['player_nationality_id'],
                            mother_name: this.user['mother_name'],
                            mother_contact: this.user['mother_contact'],
                            mother_email: this.user['mother_email'],
                            father_email: this.user['father_email'],
                            father_name: this.user['father_name'],
                            father_contact: this.user['father_contact'],
                            player_guardian_name: this.user['player_guardian_name'],
                        })
                    }
                }
            }
        })
    }

    /**
     * thisIsFunction
     * 发送注册邮箱code
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    postMailCode() {
        // let config = {
        //     player_email: this.setForm.value.player_email,
        //     type: 'changeEmail'
        // };
        // this.dataService.postBaseData('code/sentMail', config).then(resp => {
        //     // alert('成功');
        //     console.log('setting', resp)
        // })
    }

    /**
     * thisIsFunction
     * 发送短信code
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    postPhoneCode() {
        // let config = {
        //     player_phone: this.setForm.value.player_phone,
        //     player_phone_area:this.setForm.value.player_phone_area,
        //     type: 'changePhone'
        // };
        // this.dataService.postBaseData('code/sentMail', config).then(resp => {
        //     // alert('成功');
        //     console.log('setting', resp)
        // })
    }

    /**
     * thisIsFunction
     * 获取手机号码区号列表
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getPhoneArea() {
        const config = {
            setting_key: 'phone_area'
        };
        this.dataService.getBaseData('setting', config).then(resp => {
        })

    }

    /**
     * thisIsFunction
     * 修改密码
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    updatePassword() {
        const config = this.passwordForm.value;
        this.dataService.postBaseData('player/updatePassword', config).then(resp => {
            if (resp.code == 200) {
                this.toastrService.success('Success', 'Modified success');
                this.loginout();
            }
        });
    }

    /**
     * thisIsFunction
     * 消息通知一键已读
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    readNotification() {
        const config = {
            notification_type: 'email'
        };
        this.dataService.putBaseData('player/notification', config).then(resp => {
        })
    }

    /**
     * thisIsFunction
     * 获取父母资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getParent() {
        this.dataService.getBaseData('player/getParent').then(resp => {
            // this.parent = resp.data;
            if (resp.data != undefined) {
                if (resp.data.length > 0) {
                    this.parent = resp.data;

                } else {
                    this.parent = {
                        "player_parent_id": "",
                        "player_parent_name": "",
                        "player_parent_family_relation": "",
                        "player_parent_phone": "",
                        "player_parent_age": ""
                    }
                }
            }
        });
    }

    /**
     * thisIsFunction
     * 增加父母资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    postParent() {
        const config = {
            notification_type: 'email'
        };
        this.dataService.postBaseData('player/insertParent', config).then(resp => {
            this.parent = resp.data;
            if (resp.data.length > 0) {
                this.parent = resp.data;

            } else {
                this.parent = {
                    "player_parent_id": "",
                    "player_parent_name": "",
                    "player_parent_family_relation": "",
                    "player_parent_phone": "",
                    "player_parent_age": ""
                }
            }
        });
    }

    /**
     * thisIsFunction
     * 修改父母资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    updateParent() {
        const config = {
            "player_parent_id": "5",
            "player_parent_name": "林。",
            "player_parent_family_relation": "父子。",
            "player_parent_phone": "13726754755。",
            "player_parent_age": "51"
        };
        this.dataService.postBaseData('player/updateParent', config).then(resp => {
        });
    }

    /**
     * thisIsFunction
     * 删除父母资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    deleteParent(id) {
        const config = {
            "player_parent_id": id
        };
        this.dataService.postBaseData('player/deleteParent', config).then(resp => {
        });
    }

    /**
     * thisIsFunction
     * 获取position
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getposition() {
        const config = {
            "table": "position_played",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.position = resp.data;
        })
    }


    /**
     * thisIsFunction
     * 获取 jersey size
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getjerseysize() {
        const config = {
            "table": "jerseysize",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.jerseysize = resp.data;
        })
    }


    getnationality() {

        const config = {
            "table": "nationality",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            // console.log('this.nationality',resp)
            this.nationality = resp.data;
        })
    }


    /**
     * thisIsFunction
     * 获取shoot
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getstudyingGrade() {
        const config = {
            "table": "studying_grade",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.studyingGrade = resp.data;
        })
    }

    /**
     * thisIsFunction
     * 获取shoot
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getshoot() {
        const config = {
            "table": "shoot",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
         this.loading = false;
        this.dataService.getCommonData(config).then(resp => {
            this.shoot = resp.data;
            //console.log(resp,this.shoot);
             this.loading = true;

        })
    }

    onPhoneup(event) {
        let input = event.target;
        let value = input.value.replace(/[^0-9-]/ig, '');
        var arr = value.split('');
        input.value = arr.join('');
        // 输完11位之后
        let phone = event.target.value.replace(/\s+/g, '');
        // console.log(phone);
    }

    onPhonepress(event) {

        // 判断是否为数字
        let inputStr = String.fromCharCode(event.keyCode);
        let re = /^[0-9-]*$/; //判断字符串是否为数字
        if (!re.test(inputStr)) {
            return false;
        }
    }

    // 改变QRCode
    changeFps(url) {
        this.personalForm.patchValue({'player_fps_photo': url});
    }

    /**
     * thisIsFunction
     * 获取shoot
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getHasEmail() {
        if (this.personalForm.invalid) {
            this.toastrService.warning('', 'Please complete the information');
            this.openDirtyControl(this.personalForm);
            return;
        }
        // return;
        const config = {
            method_type: 'web',
            player_id: this.user.player_id,
            player_email: this.personalForm.value.player_email,
        };
        this.dataService.postBaseData('player/checkisEmailexist', config).then(resp => {
            if (resp.code == 200 && resp.data) {
                if (!resp.data.is_exist) {
                    this.updateProfile()
                } else {
                    this.toastrService.warning('', 'Player email already exists');
                }
            }
        })
    }

    // 打开脏检验
    openDirtyControl(data) {
        for (const i in data.controls) {
            this.personalForm.controls[i].markAsDirty();
            this.personalForm.controls[i].updateValueAndValidity();
        }
    }

    // 关闭脏校验
    closeDirtyControl(data) {
        for (const i in data.controls) {
            this.personalForm.controls[i].clearValidators();
            this.personalForm.controls[i].updateValueAndValidity();
        }
    }


    funcShowDetail() {
        this.showDetail = false;
        this.showCampsDetail = false;
        this.showTournamentDetail = false;
        this.showEventsDetail = false;

        this.showDetailAll = false;
        window.scrollTo(0, 0)
    }
}
