import {Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef, ɵConsole,Renderer2} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

@Component({
  selector: 'app-banquet-detail',
  templateUrl: './banquet-detail.component.html',
  styleUrls: ['./banquet-detail.component.scss']
})
export class BanquetDetailComponent implements OnInit {
   loading: boolean = true;
    datalist: any;
    campsId:any='';
    playerInfo: any = {};
    annualForm: FormGroup;
    hasUser = null;
    showQR: boolean = false;
    annualOdervalue: boolean = false;
    annualData =[];
    modalRef: BsModalRef;
 safeSrc: SafeResourceUrl;
    alipaylink: any = '';
    Interval: any = '';

    annual_banquet_detail = 'ANNUAL BANQUET';
    
    //存放 fee 相关选项数据
    feeOptionData =[];
    annual_banquet_division =[];
    annual_banquet_team =[];


    feeOrderitem = [];

    objFee ={};


    campHotel =[];//暂时
    feeSelect = [];
    objSummber={};
    
    //
    feeData =[];
    divisionAndTeamData =[];

    // divisionArray =[];
    // teamArray = [];
    // feeArray = [];
    annualArray  =[];

    //用来存价格和选项名
    divisionValue=[];
    teamValue = [];
    feeValue=[];



    contentDetail = [];
    subcontent = [];
    FeeGroupListLength: any = '';

    //feeSelect = [];
    // setForm: FormGroup;
    player_last_name = '';
    player_first_name = '';
    player_phone = '';
    player_email = '';
    player_spare_email = '';



    annual_banquet_order_player_id = '';
    annual_banquet_order_id ='' ;
    annual_banquet_order_sn = '';
    annual_banquet_order_status ='' ;

    annual_banquet_division_id ='';
    annual_banquet_team_id ='';

    
    feeGroupArr = [];
    feeGroupOptionArr = [];

    remarks: any = '';
    count = 0;

    @ViewChild('template', {static: true}) templateView: ElementRef;
    @ViewChild('template_wechat', {static: true}) template_wechat: ElementRef;

    constructor(
                private renderer2: Renderer2,
                public dataService: DataService,
                private activatedRoute: ActivatedRoute,
                private el:ElementRef,
                private router: Router,
                private modalService: BsModalService,
                private sanitizer: DomSanitizer) {
        this.hasUser = localStorage.getItem("user") || null

    }


    ngOnInit() {

        this.getData();
        this.getAnnualMenu();
        if (this.hasUser) {
            //this.getFeeGroupList();
            //this.annualOrder();
           
            this.getDataSelect('annual_banquet_division');
            this.getDataSelect('annual_banquet_team');
            let userData = localStorage.getItem("user");
            this.playerInfo = JSON.parse(userData) || [];
            //console.log('playerInfo',this.playerInfo);
            //console.log('father_email',this.playerInfo['father_email']);
            this.getAnnualData('type');
            this.getFeeAndOption();
        }else{

            // this.getCampsBusData();
            // this.getCampsHotelData();
            this.initCampData();
        }

        //新订单使用playerInfo
        this.player_last_name = this.playerInfo['player_last_name'];
        this.player_first_name = this.playerInfo['player_first_name'];
        this.player_phone = this.playerInfo['player_phone'];
        this.player_email = this.playerInfo['player_email'];


        this.annualForm = new FormGroup({
            annual_banquet_order_player_id: new FormControl(this.playerInfo['player_id'], [Validators.required]),
            annual_banquet_order_player_last_name: new FormControl(this.player_last_name, [Validators.required]),
            annual_banquet_order_player_first_name: new FormControl(this.player_first_name, [Validators.required]),
            annual_banquet_order_phone: new FormControl(this.player_phone, [Validators.required]),
            annual_banquet_order_email: new FormControl(this.player_email, [Validators.required, Validators.pattern(EMAILPATTERN)]),
            annual_banquet_order_email2: new FormControl(this.player_spare_email, [Validators.pattern(EMAILPATTERN)]),
            
            annual_banquet_division_id: new FormControl(this.annual_banquet_division_id, [Validators.required]),
            annual_banquet_team_id: new FormControl(this.annual_banquet_team_id, [Validators.required]),

            annual_banquet_order_remarks: new FormControl(this.remarks),
            annual_banquet_order_cost: new FormControl(this.count, [Validators.required]),

            annual_banquet_fee_option_value: new FormControl(this.feeValue, [Validators.required]),
            //fee: new FormControl(this.feeSelect, [Validators.required]),
            // annual_banquet_fee_option_value:new FormControl(this.feeSelect),

        });
        
    }
    

    initCampData(data?){
        if(this.annual_banquet_order_id){
           
            this.annualForm.patchValue({

                annual_banquet_order_player_id: this.playerInfo['player_id'],
                annual_banquet_order_player_last_name: this.player_last_name,
                annual_banquet_order_player_first_name: this.player_first_name,
                annual_banquet_order_phone: this.player_phone,
                annual_banquet_order_email: this.player_email,
                annual_banquet_order_email2:this.player_spare_email,

                annual_banquet_division_id: new FormControl(this.annual_banquet_division_id),
                annual_banquet_team_id: new FormControl(this.annual_banquet_team_id),


                annual_banquet_order_remarks: this.remarks,
                annual_banquet_order_cost: this.count,

                //fee: new FormControl(this.feeSelect, [Validators.required]),
                annual_banquet_fee_option_value:new FormControl(this.feeValue),
                
            });
        }
    }

    //获取annual form 信息
    getData() {
        this.loading = true;
        const config = {
            "table": "development",
            // "select":"player_name",
            "where": {
                "development_type": 'annual-banquet-detail'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }

    getAnnualMenu() {
        let config = {
            "table": "development",
            "where": {
                "development_type": 'annual-banquet-detail'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                if(resp.data.development_link){
                    this.annual_banquet_detail = resp.data.development_link;
                }
                
            }
        })
   }

    //获取 fee group 以及 fee option
    getFeeAndOption(){
         this.dataService.getBaseData('AnnualBanquet/getFeeGroupList').then(resp => {
            if (resp.code === 200) {
                //this.contentDetail = resp.data;
                this.feeOptionData = resp.data;
                //console.log('Feeoption',resp.data);
                this.FeeGroupListLength = resp.data.length
                this.subcontent = []
                resp.data.map(item => {
                    this.subcontent.push(item.fee_option)
                })
            }

        })
    }

    onSubmit() {
        
        this.annualForm.patchValue({
            "annual_banquet_order_cost": this.count,
            "annual_banquet_fee_option_value": this.feeValue,
            "annual_banquet_division_id": this.annual_banquet_division_id,
            "annual_banquet_team_id": this.annual_banquet_team_id,
        })
        


        if (!this.annualForm.valid) {
            alert('Please complete the information');
            this.openDirtyControl();
            return;
        } else {
            //console.log('formdata',JSON.stringify(this.annualForm.value));
            //return;
            this.uploadData();
        }
    }

    openDirtyControl() {
        for (const i in this.annualForm.controls) {
            this.annualForm.controls[i].markAsDirty();
            this.annualForm.controls[i].updateValueAndValidity();
        }
    }

    getDataSelect(tableName) {
        const config = {
            "table": tableName,
            "where": {},
            "page": "1",
            "perPage": "10000"
        };


        this.dataService.getCommonData(config).then(resp => {
            let returnData = [];
            if (resp.code === 200) {
                // resp.data.forEach((val, key) => {
                //     returnData.push({'name': val[tableName + '_name']+' ... $'+val[tableName + '_price'], 'id': val[tableName + '_id']});
                // });
                this[tableName] = resp.data;
               // console.log(tableName,resp.data);
            }
        })
    }

    uploadData() {

        const config = JSON.stringify(this.annualForm.value);
        // console.log(config);
        // return;
        this.dataService.postBaseData('Annual_order/annual', config).then(resp => {
            if (resp.code === 200) {
                //console.log('resp.data',resp.data);
                //暂时去掉，不然back时信息会空白
                //this.annualForm.reset();
                this.showQR = true
                this.getAnnualData()
                this.annualOrder()
            }
        })
    }
    
    //计算总数
    feeCount(){

        this.count = 0;

        // console.log('divisionValue',this.divisionValue);
        // console.log('teamValue',this.teamValue);
        // console.log('feeValue',this.feeValue);

        if(this.divisionValue.length){
            this.divisionValue.forEach((val,key)=>{
                this.count += Number(val.price);
            }); 
        }

        if(this.teamValue.length){
            this.teamValue.forEach((val,key)=>{
                this.count += Number(val.price);
            }); 
        }

         if(this.feeValue.length){
            this.feeValue.forEach((val,key)=>{
                this.count += Number(val.price*val.number);
            }); 
        }

       // console.log('feecount',this.count);


      
    }


    divisionChange(value) {

        this.divisionValue = [];
        this.annual_banquet_division_id = '';
        this.annual_banquet_division.forEach((dtData)=>{
            if(value == dtData.annual_banquet_division_id){
                this.divisionValue.push({ 
                   "division_id":value,
                   "price":dtData.annual_banquet_division_price}
                );
                this.annual_banquet_division_id = value;
                return true;
            }
        })
        this.feeCount();

    }

    teamChange(value) {

        this.teamValue = [];
        this.annual_banquet_team_id = '';
        this.annual_banquet_team.forEach((dtData)=>{
            if(value == dtData.annual_banquet_team_id){
                this.teamValue.push({ 
                   "team_id":value,
                   "price":dtData.annual_banquet_team_price}
                );
                
                this.annual_banquet_team_id =value;
                return true;
            }
        })
        
        this.feeCount();

    }

    feeChange2(value,index){
        this.objFee[index] = value;
        //console.log('feeChange2',this.objFee);
    }

    feeChange(event,index){

        //console.log('I am index:',index);
        let id = event.target.value;
        this.objFee[index] = id;
        let numberObj = event.target.parentNode.parentNode.querySelector(".annual_banquet_fee_option_number");
        let inputValue = numberObj.value;
        if(id){
           // console.log('11');
            if(inputValue ==0 || inputValue==''){
                numberObj.value = 1;
                inputValue = 1;
            }
            
        }else{
           // console.log('22');
            numberObj.value = 0;
            inputValue = 0;
        }

        //记录fee 价格和id
        let idArr =[];
        Object.keys(this.objFee).forEach(i => {
            if(this.objFee[i]){
                idArr.push(this.objFee[i]);
            }
        })

        //console.log('idArr',idArr);return;
        Object.keys(this.objFee).forEach(i => {
            if(this.objFee[i]){
                //有值时判断是否存在，存在则更新，不在则添加
                if(this.feeValue.length){
                    let isnew = true;
                    
                    this.feeValue.forEach((feeData,key)=>{
                        if(this.objFee[i] == feeData.fee_id){
                            if(this.objFee[i] == id){
                                feeData.number = inputValue;
                            }
                            
                            isnew = false;
                            return true;
                        }
                       
                    }); 

                    if(isnew){

                        this.subcontent[i].forEach((dtData)=>{
                            if(this.objFee[i] == dtData.annual_banquet_fee_option_id){
                                this.feeValue.push({ 
                                    "fee_id":dtData.annual_banquet_fee_option_id,
                                    "price":dtData.annual_banquet_fee_option_price,
                                    'number':inputValue
                                });
                                return true;
                            }
                        }); 
                    }


                        
                }else{
                    //初始化时直接添加
                    this.subcontent[i].forEach((dtData)=>{
                        if(this.objFee[i] == dtData.annual_banquet_fee_option_id){
                            this.feeValue.push({ 
                               "fee_id":dtData.annual_banquet_fee_option_id,
                               "price":dtData.annual_banquet_fee_option_price,
                               'number':inputValue
                            });
                            return true;
                        }
                    }); 
                }
            }

        })

        if(this.feeValue.length){
            this.feeValue.forEach((feeData,key)=>{
             
                let isexist= idArr.indexOf(feeData.fee_id);
                //console.log('is',is);
                if(isexist <0){
                    this.feeValue.splice(key,1);
                }
               

            }); 
        }
       // console.log('feevalue2',this.feeValue);
        // console.log('feeChange',this.objFee);
        this.feeCount();
    }

    feeNumberChange(event,index){
        //console.log('index1',index);
        //对应的select框
        let selectObj = event.target.parentNode.parentNode.querySelector(".feeSelect");
        let selectValue = selectObj.value;
        //console.log('value',selectValue);

        let value = event.target.value;
        let realValue = value;
        if(value == '' || value == 0){
            event.target.value =1;
            realValue = 1;
        }
        //重新赋值对应的number
        this.feeValue.forEach((val, key) => {

            if(val.fee_id == selectValue){
                val.number = realValue;
                return true;
            }
        })
        //console.log('realValue',realValue);

        this.feeCount();
        //console.log('feeValue3',this.feeValue);

    }


   getAnnualData(type?) {

        this.dataService.getBaseData('Annual_order/annual_data').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    
                    this.feeData = [];

                    this.feeOrderitem = []
                    this.annualData = resp.data.annual;
                     //console.log(this.campsData);
                    if(resp.data.annual != null){

                        // division 和 team 
                        if (resp.data.annual.divisionAndTeam.length > 0) {
                            let divisionAndTeam = resp.data.annual.divisionAndTeam;
                            this.divisionAndTeamData = divisionAndTeam;
                            let divisionArray= [];
                            let teamArray= [];
                            this.annualArray = [];
                            this.annualOdervalue = true 
                            this.showQR = true;

                             window.scroll({ 
           top: 100, 
           left: 0, 
           behavior: 'smooth' 
    });
                            //需要在详情页显示的
                            divisionAndTeam.forEach((dtData)=>{
                                if(dtData.z_annual_banquet_option_type =='division'){
                                    this.annual_banquet_division_id = dtData.z_annual_banquet_option_id;
                                    // divisionArray.push((dtData.z_annual_banquet_option_name) + '...$' +(dtData.z_annual_banquet_option_price));

                                    //暂时屏蔽价格
                                    divisionArray.push(dtData.z_annual_banquet_option_name);


                                   
                  
                                }
                                if(dtData.z_annual_banquet_option_type =='team'){
                                    this.annual_banquet_team_id = dtData.z_annual_banquet_option_id;
                                    //teamArray.push((dtData.z_annual_banquet_option_name) + '...$' +(dtData.z_annual_banquet_option_price));

                                    //暂时屏蔽价格
                                    teamArray.push(dtData.z_annual_banquet_option_name);

                                   
                                }
                            })

                            this.annualArray.push({"division":divisionArray});
                            this.annualArray.push({"team":teamArray});
                        }


                        //fee
                        if(resp.data.annual.feeData.length > 0){
        
                            let feeData = resp.data.annual.feeData;
                            this.feeData = feeData;

                            //let feeOptionArray = [];
                            
                            feeData.forEach((dtData)=>{
                                this.feeOrderitem.push((dtData.z_annual_banquet_fee_option_name) + '...$' +(dtData.z_annual_banquet_fee_option_price)+"/"+dtData.z_annual_banquet_fee_option_number+" person");

                                // this.feeValue.push(
                                //         { "fee_id":this.z_annual_banquet_fee_option_id,
                                //         "price":dtData.z_annual_banquet_fee_option_price,
                                //         "number":dtData.z_annual_banquet_fee_option_number}
                                // );
                                
                            })
                          
                           // this.annualArray.push({"fee":feeOptionArray});
                        }

                        this.annual_banquet_order_player_id = resp.data.annual.annual_banquet_order_player_id;
                        this.annual_banquet_order_id = resp.data.annual.annual_banquet_order_id;
                        this.annual_banquet_order_sn = resp.data.annual.annual_banquet_order_sn;
                        this.annual_banquet_order_status = resp.data.annual.annual_banquet_order_status;

                        this.player_last_name = resp.data.annual.annual_banquet_order_player_last_name;
                        this.player_first_name = resp.data.annual.annual_banquet_order_player_first_name;
                        this.player_phone = resp.data.annual.annual_banquet_order_phone;
                        this.player_email = resp.data.annual.annual_banquet_order_email;
                        this.player_spare_email =resp.data.annual.annual_banquet_order_email2;
                        this.remarks = resp.data.annual.annual_banquet_order_remarks;
                        //console.log('player_birth',this.player_birth);



this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://uat.hktyphoons.com/paypal/?pay=annual_banquet&order_sn=" + this.annual_banquet_order_sn);


                        

                    }
                    // console.log('order_id',this.annual_banquet_order_id);
                   
                    if(type){

                        //this.getCampsBusData();
                        //this.getCampsHotelData();
                        this.initCampData(resp.data.annual);
                        console.log('type');
                    }
                    // console.log('this.annualArray',this.annualArray);
                    // console.log('feeOrderitem',this.feeOrderitem);
                   //console.log('getAnnualData')

                }
            }
        })

        //this.initCampData();
         
        
        
    }

    back() {
        //console.log('annual_banquet_division',this.annual_banquet_division);
        this.showQR = false
        this.count = 0

        if (this.Interval) {
            clearInterval(this.Interval);
        }

        this.divisionValue = [];
        this.teamValue = [];
        this.feeValue = [];

        //this.feeGroupArr = [];

        //重新對原本的值賦值
        //fee data
        //console.log('subcontent',this.subcontent);

       
        if(this.annualData){
           //console.log('annualdata',this.annualData);
           //console.log('back_division',this.annual_banquet_division);
           //console.log('back_team',this.annual_banquet_team);
            //division 和 team 的 价格以及id 数组
            if(this.divisionAndTeamData.length){
                //let divisionAndTeam = this.annualData.divisionAndTeam;
                this.divisionAndTeamData.forEach((dtData)=>{
                    if(dtData.z_annual_banquet_option_type =='division'){
                       
                        this.divisionValue.push(
                            { "division_id":this.annual_banquet_division_id,
                            "price":dtData.z_annual_banquet_option_price}
                        );
                
                    }
                    if(dtData.z_annual_banquet_option_type =='team'){

                        this.teamValue.push(
                            { "team_id":this.annual_banquet_division_id,
                            "price":dtData.z_annual_banquet_option_price}
                        );

                    }
                })
            }

            if(this.annual_banquet_division.length){
                this.annual_banquet_division.forEach((dtData)=>{
                   // console.log(dtData.annual_banquet_division_id+"==="+this.annual_banquet_division_id);
                    if(dtData.annual_banquet_division_id == this.annual_banquet_division_id){
                        dtData['annual_banquet_division_is_selected'] = 'selected';
                    }
                })   

            }

           // console.log('division2',this.annual_banquet_division);

            if(this.annual_banquet_team.length){
                this.annual_banquet_team.forEach((dtData)=>{
                    if(dtData.annual_banquet_team_id == this.annual_banquet_team_id){
                        dtData['annual_banquet_team_is_selected'] = 'selected';
                    }
                })   

            }

            if(this.feeOptionData.length && this.feeData.length){
                // console.log('back_fee_option',this.feeOptionData);
                // console.log('this.feeData',this.feeData);

                this.feeOptionData.forEach((feeGroupData,key)=>{

                    if(feeGroupData.fee_option.length){

                       // console.log('feeGroupData',feeGroupData.fee_option);
                        feeGroupData.fee_option.forEach((feeOptionData)=>{
                            feeOptionData['annual_banquet_fee_option_number'] = 0;
                            feeOptionData['annual_banquet_fee_option_selected'] = "";
                            this.feeData.forEach((feeOrderData)=>{
                                if(feeOrderData.z_annual_banquet_fee_option_id == feeOptionData.annual_banquet_fee_option_id){
                                    this.feeValue.push(
                                         {"fee_id":feeOrderData.z_annual_banquet_fee_option_id,
                                         "price":feeOrderData.z_annual_banquet_fee_option_price,
                                        "number":feeOrderData.z_annual_banquet_fee_option_number}
                                    );
                                    feeGroupData['annual_banquet_fee_group_number'] = feeOrderData.z_annual_banquet_fee_option_number;
                                    feeOptionData['annual_banquet_fee_option_selected'] = "selected";
                                    feeOptionData['annual_banquet_fee_option_number'] = feeOrderData.z_annual_banquet_fee_option_number;
                                    //console.log('value',feeOrderData.z_annual_banquet_fee_option_id+"==="+feeGroupData.annual_banquet_fee_group_id);
  
                                    this.feeChange2(feeOrderData.z_annual_banquet_fee_option_id,key);
                                    return true;
                                    
                                }
                                
                                   
                            })
                            

                        })
                    }
                     
                    
                })
                //console.log('newFeeOption',this.feeOptionData);

            }

            // console.log('newfeeOptionData',this.feeOptionData);




        }
        
        this.feeCount();





    }

    annualOrder() {

        this.Interval = setInterval((val) => {
            this.getAnnualData();
        }, 1000000)
        //1000
    }

    ngOnDestroy() {
        if (this.Interval) {
            clearInterval(this.Interval);
        }
    }

    openModal(template) {
        this.modalRef = this.modalService.show(template);
    }

    isSmartDevice() {
        var userAgentInfo = navigator.userAgent;
        var mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Silk", "BlackBerry", "Opera Mini", "IEMobile"];
        var flag = false;
        //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
            if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                flag = true;
                break;
            }
        }
        return flag;
    }


    annualPayment(status) {


        let is_mobile = this.isSmartDevice();

        const config = JSON.stringify({
            player_id: this.annual_banquet_order_player_id,
            order_id: this.annual_banquet_order_id,
            order_sn: this.annual_banquet_order_sn,
            status: status,
            is_mobile: is_mobile,
        });
        // console.log('config',config);
        // return;
        this.dataService.postBaseData('pay/annual_payment_before', config).then(resp => {

            if (resp != undefined) {
                if (resp.code === 200) {
                    if (resp.data.pay_url) {
                        this.annual_banquet_order_sn = resp.data.order_sn;
                        clearInterval();

                        if (status == 'alipay') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.templateView);
                                return;
                            }
                        }
                        if (status == 'wechat') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.template_wechat);
                                return;
                            }
                        }
                        window.open(resp.data.pay_url, '_self');
                    }
                }
            }
        })

    }

   

    onPhoneup(event) {
        let input = event.target;
        // console.log('inputvalue',input.value);
        let value = input.value;
        let re = /^[1-9]\d*$/;
        if (!re.test(value)) {
            //return false;
             input.value = '';
        }

    }





}
